<template>
   <div class="home">
      <b-container>
         <b-row class="justify-content-center">
            <b-col cols="10">
               <b-input-group class="m-4">
                  <input v-if="!buscarPorDimensiones" v-model="search" type="text" class="form-control input-search m-2" placeholder="Referencia" aria-label="Referencia" v-on:keyup.enter="buscarRef()">
                  
                  <template v-else>
                     <!-- <input cols="3" type="text" class="form-control input-search mx-2 my-2 dim" placeholder="Marca" v-model="marca" /> -->
                     <b-form-select class="form-control input-search my-2 mx-2 dim" v-model="marcaSel" :options="marcas"></b-form-select>

                     <input type="text" class="form-control input-search my-2 dim" placeholder="T" v-model="t" />
                     <div class="input-group-append mt-2">
                        <button v-b-modal.modalMedidas class="btn btn-outline-secondary">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="32" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/></svg></button>
                     </div>

                     <input type="text" class="form-control input-search ms-2 my-2 dim" placeholder="A" v-model="a" />
                     <div class="input-group-append mt-2">
                        <button v-b-modal.modalMedidas class="btn btn-outline-secondary">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="32" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/></svg></button>
                     </div>

                     <input type="text" class="form-control input-search ms-2 my-2 dim" placeholder="R" v-model="r" />
                     <div class="input-group-append mt-2">
                        <button v-b-modal.modalMedidas class="btn btn-outline-secondary">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="32" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/></svg></button>
                     </div>
                  </template>

                  <div class="input-group-append">
                     <b-form-select v-model="tipoBusqueda" :options="options" class="select-tipo-busqueda m-2"></b-form-select>
                  </div>
                  <div class="input-group-append mx-2">
                     <b-button v-if="!buscarPorDimensiones" @click="buscarRef()" class="my-2 btn-buscar" variant="danger">Buscar</b-button>
                     <b-button v-else @click="buscarRefDim()" class="my-2 btn-buscar" variant="danger">Buscar</b-button>
                  </div>
               </b-input-group>
            </b-col>
         </b-row>
         
      </b-container>
   
      <modal-medidas />
      <modal-figuras @getFigura="getFigura"/>

   </div>
</template>

<script>
   import axios from 'axios'
   import ModalMedidas from '@/components/Modals/Medidas.vue'
   import ModalFiguras from '@/components/Modals/Figuras.vue'
   export default {
      name: 'BuscadorSimple',
      data () {
         return {
            search: '',
            tipoBusqueda: 'buscar-ref-asein',
            options: [
               { value: 'buscar-ref-asein', text: 'Ref. ASEIN' },
               { value: 'buscar-por-dimensiones', text: 'Por Dimensiones' }
            ],
            marcas: [],
            t: null,
            r: null,
            a: null,
            marca: null,
            marcaSel: '-- Marca --',
            buscarPorDimensiones: false
         }
      },
      components: { ModalMedidas, ModalFiguras },
      methods: {
         buscarRef () {
            let returnData = {
               items: [],
               mostrarMsg: false,
               msg: ''
            };   
            axios({url: 'https://api.carretillas.asein.com/buscar-ref-asein' + '?search=' + this.search, method: 'POST' })
            .then(result => {
               returnData['items'] = result.data['items']

               if ((this.search.length) > 0 && (result.data['num_items'] > 5)) {
                  returnData['mostrarMsg'] = true
                  returnData['msg'] = "Se han encontrado demasiadas coincidencias. Por favor, afine su búsqueda."
               } else if ((this.search.length) > 0 && (result.data['num_items'] == 0)) {
                  returnData['mostrarMsg'] = true
                  returnData['msg']= "¿No encuentra la escobilla?"
               } else {
                  returnData['mostrarMsg'] = false
                  returnData['msg'] = ''
               }
               this.$emit("getData", returnData);
            })
            .catch(err => {
               console.log(err)
            })
         },
         cargarMarcas () {
            axios({url: 'https://api.carretillas.asein.com/cargar-marcas', method: 'GET' })
            .then(result => {
               this.marcas = result.data['marcas']
            })
            .catch(err => {
               console.log(err)
            })
         },
         buscarRefDim () {
            let marca = null
            if (this.marcaSel != '-- Marca --') {
               marca = this.marcaSel
            }

            let returnData = {
               items: [],
               mostrarMsg: false,
               msg: ''
            };
            let busquedaDim = 1
            let t = this.t
            if (t != null) {
               t = t.replace('.', ',')
               busquedaDim++
            }
            let r = this.r
            if (r != null) {
               r = r.replace('.', ',')
               busquedaDim++
            }
            let a = this.a
            if (a != null) {
               a = a.replace('.', ',')
               busquedaDim++
            }
            let figura = this.figura
            if (figura != null) {
               figura = this.figura
               busquedaDim++
            }
            let items = {}
            items.items = []

            axios({url: 'https://api.carretillas.asein.com/buscar-por-dimensiones?marca=' + marca + '&t=' + t + '&r=' + r + '&a=' + a, method: 'POST' })
            .then(result => {
               console.log (result.data)
               console.log(busquedaDim)
               console.log(result.data['num_items'])
               returnData['items'] = result.data['items']

               if ((busquedaDim > 0) && (result.data['num_items'] > 5)) {
                  returnData['mostrarMsg'] = true
                  returnData['msg'] = "Se han encontrado demasiadas coincidencias. Por favor, afine su búsqueda."
               } else if ((busquedaDim > 0) && (result.data['num_items'] == 0)) {
                  returnData['mostrarMsg'] = true
                  returnData['msg']= "No se han encontrado resultados."
               } else {
                  returnData['mostrarMsg'] = false
                  returnData['msg'] = ''
               }
               this.$emit("getData", returnData);
            })
            .catch(err => {
               console.log(err)
            })
         },
         showModalMedidas () {
            this.$refs['modal-medidas'].show()
         },
         getFigura(data) {
            this.figura = data['figura']
         }
      },
      watch: {
         tipoBusqueda () {
            if (this.tipoBusqueda == 'buscar-por-dimensiones') {
               this.buscarPorDimensiones = true
               this.cargarMarcas()
            } else {
               this.buscarPorDimensiones = false
            }
         }
      }
   }
   
</script>

<style scoped>
   .link {
      text-align: center;
      font-size: 20px;
   }
   .link a {
      color: red;
   }
</style>

<template>
   <b-modal id="modal-solicitud-info" ref="modal-solicitud-info" title="Solicitud de información">
      <b-form>

         <b-form-group id="input-group-1" label="Nombre y Apellido:" label-for="input-nombre" class="mb-4">
            <b-form-input id="input-nombre" v-model="form.nombre" placeholder="Escriba su nombre completo" required></b-form-input>
         </b-form-group>

         <b-form-group id="input-group-2" label="Email:" label-for="input-email" class="mb-4">
            <b-form-input id="input-email" v-model="form.email" type="email" placeholder="Escriba su email" required></b-form-input>
         </b-form-group>

         <b-form-group id="input-group-2" label="Teléfono:" label-for="input-telefono" class="mb-4">
            <b-form-input id="input-telefono" v-model="form.telefono" type="text" placeholder="Escriba su teléfono" required></b-form-input>
         </b-form-group>

         <b-form-group id="input-group-3" label="Consulta:" label-for="input-consulta" class="mb-4">
            <b-form-textarea id="consulta" v-model="form.msg" placeholder="Escriba su consulta..." rows="4" max-rows="8"></b-form-textarea>
         </b-form-group>

      </b-form>

      <template #modal-footer>
         <b-button v-if="!mostrarMsg" @click="EnviarForm()" type="submit" variant="primary">Enviar</b-button>
         <p v-else>Correo enviado!</p>
         <b-button variant="danger" @click="cancel()">Cerrar</b-button> 
      </template>

   </b-modal>
</template>

<script>
   import axios from 'axios'
   export default {
      name: 'ModalSolicitudInfo',
      data () {
         return {
            form: {
               nombre: '',
               email: '',
               telefono: '',
               msg: ''
            },
            mostrarMsg: false,
         }
      },
      methods: {
         EnviarForm () {
            axios({url: 'https://api.carretillas.asein.com/solicitar-informacion?nombre=' + this.form.nombre + '&email=' + this.form.email + '&telefono=' + this.form.telefono + '&msg=' + this.form.msg, method: 'POST' })
            .then(result => {
               console.log(result.data)
               if (result.data['correo'] == 'success') {
                  this.mostrarMsg = true
               } else {
                  this.mostrarMsg = false
               }
            })
            .catch(err => {
               console.log(err)
            })
         },
         cancel () {
            this.form.nombre = ''
            this.form.email = ''
            this.form.telefono = ''
            this.form.msg = ''
            this.$bvModal.hide('modal-solicitud-info')
         }
      }
   }
</script>
<template>
  <b-container class="my-5">
     <b-row v-for="(item, index) in items" :key="index" style="max-width: 960px;" class="mx-auto mt-4">
        <b-col cols="4" class="p-0 carrusel">
            <b-carousel id="carousel-1" v-model="slide" :interval="4000" controls img-width="50" @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
               <b-carousel-slide :img-src="urlImage(item.medidas.figura)">
                  <template #img>
                     <img
                        class="d-block img-fluid slide"
                        :src="urlImage(item.medidas.figura)"
                     >
                  </template>
               </b-carousel-slide>
            </b-carousel>
        </b-col>

        <b-col cols="2" class="bg-ref-asein">
           <div class="card-body p-2">
               <p class="card-text mb-0">REF ASEIN</p>
               <p class="card-text mb-0" style="font-size: 25px;"><b>{{ formatearRef(item.ref_asein) }}</b></p> 
            </div>
        </b-col>

        <b-col cols="3" class="bg-ref-fabricante">
            <div class="card-body p-2">
               <div class="mb-3" v-for="(value, index) in item.ref_fabricante" :key="index">
                  <p class="card-text mb-0"><b>Ref. {{ value.marca  }}</b></p>
                  <p class="card-text mb-0" v-for="(value2, index) in value.referencias" :key="index">
                     {{ value2 }}
                  </p>
               </div>
            </div>
        </b-col>

        <b-col cols="3" class="bg-dimensiones">
            <b-row>
               <b-col cols="6">
                  <div class="card-body p-2" style="padding-right: 0;">
                     <p class="card-text mb-0"><b>DIM. (mm)</b></p>
                     <p class="card-text mb-0"><b>T: </b> {{ item.medidas.t }}</p>
                     <p class="card-text mb-0"><b>A: </b> {{ item.medidas.a }}</p>
                     <p class="card-text mb-0"><b>R: </b> {{ item.medidas.r }}</p>
                     <p class="card-text mb-0"><b>Material: </b> {{ item.medidas.material }}</p>
                     <p v-if="item.medidas.voltaje.length > 0" class="card-text mb-0"><b>Voltaje: </b> {{ item.medidas.voltaje }}</p>
                  </div>
               </b-col>
              <b-col cols="6">
                  <img src="@/assets/img/medidas-escobillas.png" class="img-thumbnail mt-2" alt="Medidas Escobillas" />
               </b-col>
            </b-row>
            <b-row class="p-2">
               <button v-b-modal.modal-solicitud-info type="button" class="btn btn-danger my-2 mt-4">Pedir Información</button>
            </b-row>

        </b-col>
     </b-row>

      <modal-info/>

  </b-container>
</template>

<script>
   import ModalInfo from '@/components/Modals/SolicitudInfo.vue'
   export default {
     name:"CardComponent",
      data() {
         return {
            slide: 0,
            sliding: null
         }
      },
      props: ['items'],
      components: { ModalInfo },
      methods: {
         onSlideStart() {
            this.sliding = true
         },
         onSlideEnd() {
            this.sliding = false
         },
         urlImage(value) {
            const imgArray = value.split("/")
            return 'http://files.asein.com/buscador/carretillas/figuras/Figura-16' + imgArray[0] + '.jpg'
         },
         formatearRef (ref) {
            return (ref.replace('/', '\n'))
         }
      }
   }
</script>

<style scoped>
   span.sr-only {
      display: none;
   }
   .carousel-control-next-icon, .carousel-control-prev-icon {
      filter: invert(1) grayscale(100)!important;
   }
   .bg-ref-asein {
      background-color: #9EA374;
      color: #fff;
   }
   .bg-ref-fabricante {
      background-color: #BEC19F;
   }
   .bg-dimensiones {
      background-color: #DEE0CC;
   }
   .img-thumbnail {
      background: transparent;
   }
   .carrusel {
      border-top: 1px solid rgba(0,0,0,.125);
      border-left: 1px solid rgba(0,0,0,.125);
      border-bottom: 1px solid rgba(0,0,0,.125);
      border-radius: .25rem;
   }
   img.d-block.img-fluid.slide {
      width: 94px;
      margin: 0 auto;
   }
</style>